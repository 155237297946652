import type { TopLevelFormatterParams } from "echarts/types/dist/shared";
import { format } from "date-fns";
import { CardContent, Card, Typography, Box, Grid } from "@mui/joy";
import useColors from "./helpers/useColors";

type AssetActivityChartTooltipProps = {
  param: TopLevelFormatterParams;
};

const defaultTypographyProps = {
  lineHeight: 1,
  level: "title-sm",
} as const;

export default function AssetActivityChartTooltip({
  param,
}: AssetActivityChartTooltipProps) {
  const colors = useColors();

  const data = Array.isArray(param) ? param[0].data : param.data;
  if (!Array.isArray(data) || data[9] !== "") return "";

  const startDate = format(data[1] as string, "dd/MM/yyyy");
  const endDate = format(data[2] as string, "dd/MM/yyyy");
  const seriesType = data[3] as keyof typeof colors.operationsHover;
  const seriesName = data[13] as string;
  const text = data[4] as string;

  const values = {
    Start: startDate,
    End: endDate,
  };

  return (
    <Card
      sx={{
        borderRadius: "sm",
        bgcolor: (t) => t.palette.common.white,
        p: 0,
        minWidth: 150,
        boxShadow: "md",
        borderColor: "neutral.300",
      }}
    >
      <CardContent>
        <Typography
          {...defaultTypographyProps}
          fontWeight="lg"
          sx={{
            color: "neutral.solidColor",
            p: 2,
            borderBottom: "1px solid var(--joy-palette-neutral-300)",
          }}
        >
          {text}
        </Typography>
        <Grid container direction="column" gap={2} p={2}>
          <Grid container gap={1} alignItems="center">
            <Box
              width={10}
              height={10}
              borderRadius={4}
              sx={{ backgroundColor: colors.operationsHover[seriesType] }}
            ></Box>
            <Typography
              {...defaultTypographyProps}
              fontWeight="lg"
              sx={{ color: "neutral.solidColor" }}
            >
              {seriesName}
            </Typography>
          </Grid>

          {Object.entries(values).map(([key, value]) => {
            return (
              <Grid container justifyContent="space-between" key={key}>
                <Typography
                  {...defaultTypographyProps}
                  fontWeight="sm"
                  sx={{ color: "neutral.solidColor" }}
                >
                  {key}
                </Typography>
                <Typography
                  {...defaultTypographyProps}
                  sx={{
                    color: "neutral.solidColor",
                    fontVariant: "tabular-nums",
                  }}
                >
                  {value}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
}
