import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, useTheme } from "@mui/joy";
import { defaultTranslations } from "library-translations";
import React from "react";
import {
  TaskCanceledIcon,
  TaskCompletedIcon,
  TaskInProgressIcon,
  TaskPlannedIcon,
} from "../..";
import { Question } from "../icons/Question";

export type StatusType = "planned" | "cancel" | "completed" | "progress";

const translationStrings = [
  "Planned",
  "Unknown",
  "Canceled",
  "Completed",
  "In progress",
] as const;

interface StatusChipProps {
  status: StatusType;
}

interface ChipConfig {
  color: "neutral" | "primary" | "warning";
  label: string;
  sx?: React.CSSProperties;
  icon: React.ReactNode;
}

export default function StatusChip({ status }: StatusChipProps) {
  const t = defaultTranslations(translationStrings);
  const theme = useTheme();

  const getChipConfig = (status: StatusType): ChipConfig => {
    const configs = {
      planned: {
        color: "neutral" as const,
        label: t["Planned"],
        sx: { borderStyle: "dashed" },
        icon: <FontAwesomeIcon icon={TaskPlannedIcon} />,
      },
      cancel: {
        color: "neutral" as const,
        label: t["Canceled"],
        icon: <FontAwesomeIcon icon={TaskCanceledIcon} />,
      },
      completed: {
        color: "primary" as const,
        label: t["Completed"],
        icon: <FontAwesomeIcon icon={TaskCompletedIcon} />,
      },
      progress: {
        color: "warning" as const,
        label: t["In progress"],
        icon: <FontAwesomeIcon icon={TaskInProgressIcon} />,
      },
    };

    const foundChip = configs[status];

    // this is a fallback for the case when the status is not found
    // it could happen if the API returns an unexpected status
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return foundChip
      ? foundChip
      : {
          color: "neutral" as const,
          label: t["Unknown"],
          sx: { color: theme.palette.neutral[600] },
          icon: (
            <FontAwesomeIcon
              icon={Question}
              style={{ color: theme.palette.neutral[600] }}
            />
          ),
        };
  };

  const config = getChipConfig(status);

  return (
    <Chip
      variant="outlined"
      color={config.color}
      startDecorator={config.icon}
      {...(config.sx && { sx: config.sx })}
    >
      {config.label}
    </Chip>
  );
}
