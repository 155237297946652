import type { TopLevelFormatterParams } from "echarts/types/dist/shared";
import colors from "../../tokens/colors";
import { Card, Grid, Stack, Typography } from "@mui/joy";
import { formatCurrency, formatNumber } from "library-frontend-utils/helpers";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import radius from "../../tokens/radius";

const translationStrings = [
  "Day",
  "Cost",
  "Ahead of schedule",
  "Behind schedule",
  "Est. VoWD",
  "Planned AFE",
] as const;

type Cost = [duration: number, cost: number];

type TooltipFormatterProps = {
  param: TopLevelFormatterParams;
  currency?: string;
  dsvCost?: Cost[];
  afeCost?: Cost[];
  translations?: TranslationsType<typeof translationStrings>;
};

function findCostDifferenceByDay(
  index: number,
  dsvCostArray: Cost[] = [],
  afeCostArray: Cost[] = []
): number | null {
  if (!dsvCostArray.length || !afeCostArray.length) {
    return null;
  }

  if (
    index < 0 ||
    index >= dsvCostArray.length ||
    index >= afeCostArray.length
  ) {
    return null;
  }

  const dsvEntry = dsvCostArray[index];
  const afeEntry = afeCostArray[index];

  return afeEntry[0] - dsvEntry[0];
}

function calculateTotalCost(
  dsvCostValue?: Cost,
  afeCostValue?: Cost
): number | null {
  if (!dsvCostValue || !afeCostValue) {
    return null;
  }

  return dsvCostValue[1] - afeCostValue[1];
}

export default function TooltipFormatter({
  param,
  currency,
  translations,
  dsvCost,
  afeCost,
}: TooltipFormatterProps) {
  const t = { ...defaultTranslations(translationStrings), ...translations };

  if (!Array.isArray(param) || !param[0] || !Array.isArray(param[0].value)) {
    return "";
  }

  const dataIndex = param[0].dataIndex;
  const costDiff =
    dsvCost && afeCost
      ? findCostDifferenceByDay(dataIndex, dsvCost, afeCost)
      : null;
  const dsvCostValue = dsvCost && dsvCost[dataIndex];
  const afeCostValue = afeCost && afeCost[dataIndex];
  const totalCost = calculateTotalCost(dsvCostValue, afeCostValue);

  const list = [];

  if (dsvCostValue) {
    list.push({
      name: t["Est. VoWD"],
      cost: currency
        ? formatCurrency(dsvCostValue[1], currency)
        : dsvCostValue[1].toString(),
      color: colors["grey"][900],
    });
  }

  if (afeCostValue) {
    list.push({
      name: t["Planned AFE"],
      cost: currency
        ? formatCurrency(afeCostValue[1], currency)
        : afeCostValue[1].toString(),
      color: colors["grey"][400],
    });
  }

  if (list.length === 0) return "";

  const day = Array.isArray(param[0].value)
    ? `${t["Day"]} ${formatNumber(Number(param[0].value[0]), 1)}`
    : null;

  let statusText = null;
  let statusColor = null;
  let formattedTotalCost = "";
  let showTotalCost = false;

  if (totalCost !== null) {
    if (totalCost < 0) {
      statusText = t["Ahead of schedule"];
      statusColor = colors["green"][600];
      formattedTotalCost = currency
        ? `-${formatCurrency(Math.abs(totalCost), currency)}`
        : totalCost.toString();
      showTotalCost = true;
    } else if (totalCost > 0) {
      statusText = t["Behind schedule"];
      statusColor = colors["red"][600];
      formattedTotalCost = currency
        ? `+${formatCurrency(totalCost, currency)}`
        : totalCost.toString();
      showTotalCost = true;
    }
  }

  return (
    <Card
      sx={{
        borderRadius: radius.sm,
        padding: 0,
        boxShadow: "md",
        backgroundColor: "common.white",
        borderColor: "neutral.300",
        width: "100%",
      }}
    >
      <Stack>
        <Grid
          sx={{
            padding: 2,
            borderBottom: `1px solid var(--joy-palette-neutral-300)`,
            color: colors["grey"][800],
          }}
        >
          <Grid
            container
            alignItems="center"
            gap={2}
            justifyContent="space-between"
          >
            <Grid container alignItems="center" gap={0.5} flex={1}>
              <Typography
                level="title-sm"
                fontWeight={600}
                textColor={colors["grey"][800]}
              >
                {day}
              </Typography>
              {costDiff !== null && showTotalCost && totalCost !== null ? (
                <Typography
                  level="title-sm"
                  fontWeight={600}
                  sx={{ color: statusColor }}
                >
                  {`(${totalCost < 0 ? "-" : "+"}${formatNumber(costDiff, 1)})`}
                </Typography>
              ) : null}
            </Grid>
            {statusText && (
              <Typography
                level="title-sm"
                fontWeight="sm"
                sx={{ color: statusColor }}
              >
                {statusText}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Stack padding={2}>
          {list.map(({ cost, color, name }, index) => (
            <Grid
              container
              key={`item-${index.toString()}`}
              justifyContent="space-between"
              alignItems="center"
              marginBottom={index < list.length - 1 ? 1 : 0}
              gap={3}
            >
              <Grid container alignItems="center">
                <span
                  style={{
                    fontWeight: 300,
                    display: "inline-block",
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    marginRight: 5,
                    background: color,
                  }}
                ></span>

                <Typography
                  textColor={colors["grey"][800]}
                  level="title-sm"
                  fontWeight={300}
                >
                  {name}
                </Typography>
              </Grid>
              <Grid sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: colors["grey"][800],
                    fontVariant: "tabular-nums",
                  }}
                  level="body-sm"
                  fontWeight={600}
                >
                  {cost}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Stack>

        {showTotalCost && (
          <Grid
            sx={{ borderTop: `1px dashed var(--joy-palette-neutral-300)` }}
            container
            justifyContent="space-between"
            alignItems="center"
            padding={2}
          >
            <Typography
              textColor={colors["grey"][800]}
              level="title-sm"
              fontWeight={300}
            >
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                Δ {t["Cost"]}
              </span>
            </Typography>
            <Typography
              sx={{
                color: statusColor,
                fontVariant: "tabular-nums",
              }}
              level="body-sm"
              fontWeight={600}
            >
              {formattedTotalCost}
            </Typography>
          </Grid>
        )}
      </Stack>
    </Card>
  );
}
