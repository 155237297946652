import {
  flexRender,
  type Cell,
  type CellContext,
  type Column,
  type ColumnDef,
  type Header,
  type Row,
  type RowData,
  type Table,
} from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandableCell from "./ExpandableCell";
import type { CostTrackingDataType } from "./CostTrackingTable";
import { Box, Skeleton, Typography } from "@mui/joy";
import { SortDownIcon, SortUpDownIcon, SortUpIcon } from "../..";
import {
  COLUMN_WIDTHS,
  HEADER_HEIGHT,
  ROW_HEIGHT,
  STICKY_GROUP_COLUMNS,
  STICKY_NORMAL_COLUMNS,
} from "./constants";

interface UseExpandableColumnProps<TData> {
  onClickExpand?: (row: Row<TData>) => void;
  columnId: string;
  headerLabel: string;
}

export function getColumnHeaderStyle<TData extends RowData, TValue>(
  header: Header<TData, TValue>
): React.CSSProperties {
  return header.column.columnDef.meta?.headerStyle ?? {};
}

export function getColumnCellStyle<TData extends RowData, TValue>(
  columnDef: Cell<TData, TValue>["column"]["columnDef"]
): React.CSSProperties {
  return columnDef.meta?.cellStyle ?? {};
}

export function sortingIcon(order: "asc" | "desc" | false) {
  if (!order)
    return (
      <FontAwesomeIcon
        icon={SortUpDownIcon}
        style={{
          fontSize: 24,
          color: "var(--joy-palette-text-tertiary)",
        }}
      />
    );
  return (
    <FontAwesomeIcon
      icon={{ asc: SortDownIcon, desc: SortUpIcon }[order]}
      style={{ fontSize: 24, fill: "var(--joy-palette-text-primary)" }}
    />
  );
}

export const createExpandableColumn = <TData,>({
  columnId,
  headerLabel,
  onClickExpand,
}: UseExpandableColumnProps<TData>): ColumnDef<TData> => ({
  id: columnId,
  header: headerLabel,
  cell: ({ row }: { row: Row<TData> }) => (
    <ExpandableCell<TData>
      row={row}
      onClickExpand={() => onClickExpand?.(row)}
    />
  ),
  meta: {
    disableWrapping: true,
    headerStyle: {
      minWidth: 60,
      maxWidth: 60,
      textAlign: "center",
    },
    cellStyle: {
      minWidth: 60,
      maxWidth: 60,
      textAlign: "center",
    },
  },
});

export const getTableStyle = {
  tableLayout: "auto",
  borderRadius: 10,
  borderCollapse: "separate",
  borderSpacing: 0,
  "--TableCell-borderColor": "var(--joy-palette-neutral-outlinedBorder)",
  "& .nested-row td:first-of-type": {
    borderBottomWidth: 0,
  },
  "& .nested-row.last td:first-of-type": {
    borderBottomWidth: 1,
  },
  "tbody tr:hover": {
    "& td": {
      backgroundColor: "var(--joy-palette-background-body)",
    },
  },
  "& tr td": {
    height: ROW_HEIGHT,
    px: 2,
    py: 0,
    fontVariant: "tabular-nums",
  },
  "tbody tr td": {
    backgroundColor: "var(--joy-palette-background-surface)",
  },
  "tbody tr td:first-child, tbody tr td:last-child": {
    borderRadius: 0,
  },
  "& tfoot tr td:first-of-type": {
    borderLeft: "1px solid var(--joy-palette-neutral-outlinedBorder)",
  },
  "& tfoot tr td:last-of-type": {
    borderRight: "1px solid var(--joy-palette-neutral-outlinedBorder)",
  },
  "tbody tr:nth-last-of-type(1) td": {
    borderBottom: 0,
  },
};

const getStickyLeftOffset = (columnIndex: number) => {
  let offset = 0;
  for (let i = 0; i < columnIndex; i++) {
    offset += COLUMN_WIDTHS[i];
  }
  return offset;
};

export const getHeaderStyles = (
  header: Header<CostTrackingDataType, unknown>,
  groupIndex: number,
  columnIndex: number,
  totalColumns: number
): React.CSSProperties => {
  const isGroup = header.column.columns.length > 0;
  const isLastColumn = columnIndex === totalColumns - 1;
  const isLastInSubGroup =
    header.column.parent &&
    header.column.parent.columns.indexOf(header.column) ===
      header.column.parent.columns.length - 1;
  const isFirstColumn = columnIndex === 0;
  const stickyLimit = isGroup ? STICKY_GROUP_COLUMNS : STICKY_NORMAL_COLUMNS;

  const stickyStyles =
    columnIndex < stickyLimit
      ? {
          left: getStickyLeftOffset(columnIndex),
          zIndex: 20,
        }
      : {};

  return {
    position: "sticky",
    top: 0,
    zIndex: 10,
    cursor: header.column.getCanSort() ? "pointer" : "auto",
    height: HEADER_HEIGHT,
    verticalAlign: "middle",
    backgroundColor: "var(--joy-palette-background-body)",
    ...(groupIndex === 0 &&
      isFirstColumn && {
        borderTopLeftRadius: 8,
      }),
    ...(groupIndex === 0 &&
      isLastColumn && {
        borderTopRightRadius: 8,
      }),
    ...(isFirstColumn && {
      borderLeft: "1px solid var(--joy-palette-neutral-outlinedBorder)",
    }),
    ...(isLastColumn && {
      borderRight: "1px solid var(--joy-palette-neutral-outlinedBorder)",
    }),
    ...((isGroup || isLastInSubGroup) &&
      !isLastColumn && {
        borderRight: "1px solid var(--joy-palette-neutral-outlinedBorder)",
      }),
    ...getColumnHeaderStyle(header),
    ...stickyStyles,
  } as React.CSSProperties;
};

export const getCellStyles = (
  column: Column<CostTrackingDataType>,
  row: Row<CostTrackingDataType>,
  index: number,
  table: Table<CostTrackingDataType>
): React.CSSProperties => {
  const isLastInGroup =
    column.parent &&
    column.parent.columns.indexOf(column) === column.parent.columns.length - 1;

  const isLastRow = row.index === table.getRowModel().rows.length - 1;
  const isExpanded = row.getIsExpanded();
  const isFirstCell = index === 0;

  const stickyStyles =
    index < STICKY_NORMAL_COLUMNS
      ? {
          position: "sticky",
          left: getStickyLeftOffset(index),
          zIndex: 11,
        }
      : {};
  const columnStyle = column.columnDef.meta?.cellStyle ?? {};

  return {
    ...(isLastInGroup && {
      borderRight: "1px solid var(--joy-palette-neutral-outlinedBorder)",
    }),
    ...(isExpanded &&
      isFirstCell && {
        borderBottom: "none",
      }),
    ...(isLastRow && {
      borderBottom: "none",
    }),
    ...columnStyle,
    ...stickyStyles,
  } as React.CSSProperties;
};

export const renderCellContent = (
  column: Column<CostTrackingDataType>,
  row: Row<CostTrackingDataType>,
  isError: boolean,
  isEmpty: boolean,
  isLoading: boolean,
  getContext: () => CellContext<CostTrackingDataType, unknown>
): React.ReactNode => {
  const isExpanderColumn = column.id === "expander";
  const cellValue = row.getValue(column.id);

  const disabledStyles =
    isLoading || isError || isEmpty
      ? {
          svg: { color: "neutral.plainDisabledColor" },
          pointerEvents: "none",
        }
      : {};

  if (!isExpanderColumn) {
    if (isLoading)
      return (
        <Typography>
          <Skeleton>Loading</Skeleton>
        </Typography>
      );
    if (isError || isEmpty) return null;
  }

  if (column.columnDef.meta?.disableWrapping) {
    return (
      <Box sx={disabledStyles}>
        {flexRender(column.columnDef.cell, getContext())}
      </Box>
    );
  }

  return (
    <Typography
      level="title-md"
      textColor={
        isExpanderColumn || cellValue ? "text.primary" : "text.tertiary"
      }
      sx={disabledStyles}
    >
      {isExpanderColumn || cellValue
        ? flexRender(column.columnDef.cell, getContext())
        : "-"}
    </Typography>
  );
};

export const getFooterCellStyles = (index: number): React.CSSProperties => {
  const stickyStyles =
    index < STICKY_NORMAL_COLUMNS
      ? {
          left: getStickyLeftOffset(index),
          zIndex: 11,
          width: COLUMN_WIDTHS[index],
          minWidth: COLUMN_WIDTHS[index],
        }
      : {};

  return {
    position: "sticky",
    bottom: 0,
    zIndex: 10,
    backgroundColor: "var(--joy-palette-background-body)",
    ...stickyStyles,
  } as React.CSSProperties;
};
