import { InformationSecurity } from "./InformationSecurity";

export const authority = <T extends string>(
  tenantId: T
): `https://login.microsoftonline.com/${T}` =>
  `https://login.microsoftonline.com/${tenantId}`;

export const apps = {
  odpDev: {
    clientId: "db805739-44d7-4aa2-a190-109b4907f2e4",
    servicePrincipalId: "a459ffc8-40a3-4a79-a2f8-b1716edbfc4b",
    company: "varenergi",
    tenantId: "77da4c42-ba77-462b-bb54-7f7ea57bd0a8",
    authority: authority("77da4c42-ba77-462b-bb54-7f7ea57bd0a8"),
    roles: [
      { id: "0d02a576-c15e-4dd8-8e3d-36a5bd503f31", name: "basic" },
      { id: "cf439c9b-299d-433c-97de-929327d37853", name: "admin" },
      { id: "b834fe0e-b624-4ac5-a06b-ed63e8b6e804", name: "extended" },
      { id: "4e12ec51-70ab-4fc0-a32b-406e91fa0bc5", name: "machine" },
    ],
    securityLevel: {
      basic: InformationSecurity.Internal,
      extended: InformationSecurity.Restricted,
      admin: InformationSecurity.Confidential,
    },
  },
  odpProd: {
    clientId: "474b19dd-ef78-4558-97e5-6340865e5307",
    servicePrincipalId: "e7b116ea-b814-4caa-8da6-4050fb818854",
    company: "varenergi",
    tenantId: "77da4c42-ba77-462b-bb54-7f7ea57bd0a8",
    authority: authority("77da4c42-ba77-462b-bb54-7f7ea57bd0a8"),
    roles: [
      { id: "0d02a576-c15e-4dd8-8e3d-36a5bd503f31", name: "basic" },
      { id: "cf439c9b-299d-433c-97de-929327d37853", name: "admin" },
      { id: "b834fe0e-b624-4ac5-a06b-ed63e8b6e804", name: "extended" },
      { id: "4e12ec51-70ab-4fc0-a32b-406e91fa0bc5", name: "machine" },
    ],
    securityLevel: {
      basic: InformationSecurity.Internal,
      extended: InformationSecurity.Restricted,
      admin: InformationSecurity.Confidential,
    },
  },
} as const;

export type AppName = keyof typeof apps;
