import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3";
import { DateRangePicker as MUIDateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import {
  type TranslationsType,
  defaultTranslations,
} from "library-translations";
import { setLicenseKey } from "../../helpers/muiLicense";
import { useEffect, useState, type ComponentProps } from "react";
import { type Input } from "@mui/joy";
import { fontSize, fontWeight } from "../../tokens/typography";
import { useTheme } from "@mui/material";
import colors from "../../tokens/colors";
import EndAdornment from "./EndEndorment";
import { getLocaleDateFormat } from "library-frontend-utils/helpers";
import { isDate, isValid, type Locale } from "date-fns";
import { loadLocaleFile, loadLocaleText, shortcutsItems } from "./helpers";

setLicenseKey();

const heightMap = {
  sm: "32px",
  md: "40px",
  lg: "48px",
} as const;

const translationStrings = [
  "Last 3 months",
  "Last 6 months",
  "Last year",
  "Year to date",
  "Confirm",
  "Cancel",
] as const;

type DateRangePickerProps = Omit<
  ComponentProps<typeof MUIDateRangePicker<Date>>,
  "value" | "onChange"
> & {
  startLabel: string;
  endLabel: string;
  disabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  size?: ComponentProps<typeof Input>["size"];
  value: [Date | null, Date | null];
  variant?: "outlined" | "contained";
  onChange: (
    value: [Date, Date],
    context: Parameters<
      NonNullable<ComponentProps<typeof MUIDateRangePicker>["onChange"]>
    >[1]
  ) => void;
};

export default function DateRangePicker({
  startLabel,
  endLabel,
  disabled,
  isLoading,
  isError,
  translations,
  size = "md",
  variant = "outlined",
  sx,
  onChange,
  ...rest
}: DateRangePickerProps) {
  const t = { ...defaultTranslations(translationStrings), ...translations };
  const theme = useTheme();
  const [adapterLocale, setAdapterLocale] = useState<Locale | null>(null);
  const [localeText, setLocaleText] = useState<Record<string, unknown> | null>(
    null
  );
  const customLocaleText = {
    okButtonLabel: t["Confirm"],
    cancelButtonLabel: t["Cancel"],
    ...localeText,
  };
  const isDarkMode = theme.palette.mode === "dark";
  const errorColor = colors.red[500];

  useEffect(() => {
    void loadLocaleFile(setAdapterLocale);
    void loadLocaleText(setLocaleText);
  }, []);

  const errorStyle = {
    borderColor: errorColor,
    color: errorColor,
    "& >:first-child ": {
      borderRight: `1px solid ${errorColor}`,
    },
    ".MuiInputLabel-root": {
      color: errorColor,
    },
    "& .MuiOutlinedInput-root > input": {
      color: errorColor,
    },
  };

  const containedStyle = {
    backgroundColor: isDarkMode ? colors.grey[900] : theme.palette.common.white,
  };

  const slotProps: ComponentProps<
    typeof MUIDateRangePicker<Date>
  >["slotProps"] = {
    fieldRoot: {
      sx: {
        mt: 4,
        ...(variant === "contained" && containedStyle),
        ...(isError && errorStyle),
        height: heightMap[size],
        "& .MuiOutlinedInput-root": {
          height: "100%",
          fontSize: fontSize[size],
        },
      },
    },
    actionBar: {
      actions: ["cancel", "accept"],
      sx: {
        display: isLoading ? "none !important" : "flex",
      },
    },
    shortcuts: {
      items: shortcutsItems(t),
      changeImportance: "set",
      sx: {
        display: isLoading ? "none !important" : "flex",
      },
    },
    popper: {
      disablePortal: true,
      className: "dateRangePickerPopper",
    },
    day: {
      role: "gridcell",
    },
    textField: {
      sx: {
        minHeight: heightMap[size],
        justifyContent: "center",
        padding: (theme) =>
          size === "sm" ? theme.spacing(0, 1, 0, 2) : theme.spacing(0, 1, 0, 3),
      },
      InputLabelProps: {
        shrink: true,
        sx: {
          top: -20,
          transform: "none",
          left: size == "sm" ? 10 : 15,
          fontSize: fontSize.sm,
          fontWeight: fontWeight.md,
        },
      },
      InputProps: {
        endAdornment: (
          <EndAdornment theme={theme} size={size} isError={isError} />
        ),
      },
    },
    fieldSeparator: { sx: { display: "none" } },
  };

  const handleDateChange: ComponentProps<
    typeof MUIDateRangePicker
  >["onChange"] = (newValue, context) => {
    // Only trigger onChange if both dates are valid Date objects
    if (
      Array.isArray(newValue) &&
      newValue.every(isDate) &&
      newValue.every(isValid)
    ) {
      const [startDate, endDate] = newValue as [Date, Date];
      // make new Date objects, as if they are the same values
      // it seems they also reference the same object
      const startTime = startDate.getTime();
      const endOfDay = endDate.setHours(23, 59, 59, 999);
      onChange([new Date(startTime), new Date(endOfDay)], context);
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={customLocaleText}
      adapterLocale={adapterLocale}
    >
      <MUIDateRangePicker<Date>
        sx={sx}
        slotProps={slotProps}
        localeText={{ start: startLabel, end: endLabel }}
        calendars={2}
        disabled={disabled}
        loading={isLoading}
        closeOnSelect={false}
        format={getLocaleDateFormat()}
        onChange={handleDateChange}
        readOnly={isError}
        {...rest}
      />
    </LocalizationProvider>
  );
}
