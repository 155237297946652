import * as echarts from "echarts/core";
import {
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  type ComponentProps,
  type MutableRefObject,
} from "react";
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
} from "echarts/components";
import EChartsRenderer from "../../helpers/ECharts/EChartsWrapper";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import type {
  EChartsOption,
  LegendComponentOption,
  LineSeriesOption,
} from "echarts";
import { Box, Checkbox, Grid, Skeleton, useColorScheme } from "@mui/joy";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import type { EchartsEventParams } from "../shared/types/EchartsEventParams";
import colors from "../../tokens/colors";
import TooltipFormatter from "./TooltipFormatter";
import useTooltipContainer from "../../helpers/useTooltipContainer";
import timeFormatter from "../../helpers/ECharts/timeFormatter";
import type ReactEChartsCore from "echarts-for-react/lib/core";
import SectionErrorState from "../SectionErrorState/SectionErrorState";
import type { DefaultColorScheme } from "@mui/joy/styles/types";
import type { YAXisOption } from "echarts/types/dist/shared";
import { calculateDynamicValuesYAxis } from "./helpers";
import { isRef } from "../../helpers/utility";

const translationStrings = [
  "Loading...",
  "No Data Available",
  "Depth (m)",
  "Error on loading data",
  "planned",
  "Days",
  "Hide All",
] as const;

type DepthLineChartAssetProps = {
  isLoading: boolean;
  onClick?: (
    event: Event,
    data: [
      wellboreId: NonNullable<
        DepthLineChartAssetProps["wellbores"]
      >[number]["id"],
      ...NonNullable<
        DepthLineChartAssetProps["wellbores"]
      >[number]["data"][number],
    ]
  ) => void;
  wellbores?: {
    id: string;
    name: string;
    data: [date: Date, depth: number][];
  }[];
  startDate: Date;
  endDate: Date;
  isError?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  renderer?: NonNullable<
    ComponentProps<typeof EChartsRenderer>["opts"]
  >["renderer"];
  onOpenFeedbackClick: () => void;
  onAskForHelpClick: () => void;
  onChangeLegend?: (selected: Record<string, boolean>) => void;
};

const colorCategories = [
  "lightGreen",
  "lime",
  "pink",
  "orange",
  "aqua",
  "aubergine",
  "sand",
  "moss",
  "purple",
] as const;

const colorShades = { light: [800, 600, 300], dark: [100, 300, 800] } as const;

type DefaultColorShades =
  (typeof colorShades)[keyof typeof colorShades][number];

type ColorShades = Record<DefaultColorShades, string>;

const colorsArray = (colorSchema: DefaultColorScheme = "light"): string[] =>
  colorCategories.flatMap((category) =>
    colorShades[colorSchema].map(
      (shade) => (colors[category] as ColorShades)[shade]
    )
  );

function DepthLineChartAsset(
  {
    isLoading,
    onClick,
    wellbores = [],
    translations,
    isError = false,
    renderer,
    startDate,
    endDate,
    onOpenFeedbackClick,
    onAskForHelpClick,
    onChangeLegend,
  }: DepthLineChartAssetProps,
  ref: ComponentProps<typeof EChartsRenderer>["ref"]
) {
  const isEmpty = wellbores.length === 0;
  const t = useMemo(
    () => ({ ...defaultTranslations(translationStrings), ...translations }),
    [translations]
  );
  const { colorScheme } = useColorScheme();
  const mainChartRef = useRef<ReactEChartsCore | null>(null);
  const legendChartRef = useRef<ReactEChartsCore | null>(null);

  const tooltipContainer = useTooltipContainer();
  const previousDateRef = useRef<Date | null>(null);

  useEffect(() => {
    if (isRef(ref)) {
      (ref as MutableRefObject<unknown>).current = legendChartRef.current;
    }
  }, [ref]);

  const handleLegendSelect = (params: {
    selected: Record<string, boolean>;
  }) => {
    if (!mainChartRef.current || !legendChartRef.current) return;

    const instance = mainChartRef.current.getEchartsInstance();
    const options = instance.getOption();

    const currentYAxis = Array.isArray(options.yAxis as YAXisOption[])
      ? (options.yAxis as YAXisOption[])[0]
      : (options.yAxis as YAXisOption);

    const updateLineStyle = (series: LineSeriesOption) => ({
      ...series,
      lineStyle: {
        ...series.lineStyle,
        opacity: series.name && params.selected[series.name] ? 1 : 0,
      },
      tooltip: {
        ...series.tooltip,
        show: series.name && params.selected[series.name],
      },
    });

    let updatedSeries: typeof options.series = [];
    if (Array.isArray(options.series)) {
      updatedSeries = options.series.map(updateLineStyle);
    } else if (options.series) {
      updatedSeries = updateLineStyle(options.series);
    }

    instance.setOption(
      {
        yAxis: {
          ...currentYAxis,
          ...calculateDynamicValuesYAxis(
            options.series as LineSeriesOption[],
            params.selected
          ),
        },
        series: updatedSeries,
      },
      {
        replaceMerge: ["series", "yAxis"],
      }
    );

    onChangeLegend?.(params.selected);
  };

  const updateMainChart = (checked: boolean) => {
    if (!mainChartRef.current) return;
    const mainInstance = mainChartRef.current.getEchartsInstance();

    const mainOptions = mainInstance.getOption();

    const updateLineStyle = (series: LineSeriesOption) => ({
      ...series,
      lineStyle: {
        ...series.lineStyle,
        opacity: checked ? 0 : 1,
      },
      tooltip: {
        ...series.tooltip,
        show: checked ? false : true,
      },
    });

    let updatedMainSeries: typeof mainOptions.series = [];
    if (Array.isArray(mainOptions.series)) {
      updatedMainSeries = mainOptions.series.map(updateLineStyle);
    } else if (mainOptions.series) {
      updatedMainSeries = updateLineStyle(
        mainOptions.series as LineSeriesOption
      );
    }

    mainInstance.setOption(
      {
        ...mainOptions,
        series: updatedMainSeries,
      },
      {
        replaceMerge: ["series"],
      }
    );
  };

  const updateLegendChart = (checked: boolean) => {
    if (!legendChartRef.current) return;
    const legendInstance = legendChartRef.current.getEchartsInstance();

    const legendOptions = legendInstance.getOption();
    const updateLegend = (
      legend?: LegendComponentOption | LegendComponentOption[],
      series?: LineSeriesOption | LineSeriesOption[]
    ) => {
      if (!legend || !series) return {};

      const seriesList = Array.isArray(series) ? series : [series];
      const selected = seriesList.reduce<Record<string, boolean>>((acc, s) => {
        if (s.name) acc[s.name] = !checked;
        return acc;
      }, {});

      return {
        ...(Array.isArray(legend) ? legend[0] : legend),
        selected,
      };
    };

    legendInstance.setOption(
      {
        ...legendOptions,
        legend: updateLegend(
          legendOptions.legend as
            | LegendComponentOption
            | LegendComponentOption[],
          legendOptions.series as LineSeriesOption | LineSeriesOption[]
        ),
      },
      {
        replaceMerge: ["legend"],
      }
    );
  };

  const handleDisableAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateMainChart(event.target.checked);
    updateLegendChart(event.target.checked);
  };

  const series = useMemo(() => {
    return wellbores.map((wellbore, index) => ({
      data: wellbore.data.map(([date, depth]) => [date, Math.round(depth)]),
      name: wellbore.name,
      id: wellbore.id,
      type: "line" as const,
      lineStyle: {
        width: 2,
        color:
          colorsArray(colorScheme)[index % colorsArray(colorScheme).length],
      },
      showSymbol: false,
      symbol: "circle",
      symbolSize: 5,
      silent: true,
      emphasis: {
        scale: 2.5,
        itemStyle: {
          cursor: "default",
          color:
            colorsArray(colorScheme)[index % colorsArray(colorScheme).length],
          borderColor: colorScheme === "dark" ? colors["grey"][900] : "#fff",
          borderWidth: 2,
        },
      },
    }));
  }, [wellbores, colorScheme]);

  const { interval, max } = useMemo(
    () => calculateDynamicValuesYAxis(series as LineSeriesOption[]),
    [series]
  );

  const options: EChartsOption = useMemo(() => {
    return {
      color: colorsArray(colorScheme),
      tooltip: {
        confine: true,
        trigger: "axis",
        borderWidth: 0,
        backgroundColor: "transparent",
        extraCssText: "box-shadow: none;",
        formatter: (params) => tooltipContainer(TooltipFormatter, params),
        position: function (
          [mouseX, mouseY]: number[],
          ...[, , , { viewSize, contentSize }]
        ) {
          let x = mouseX + 15;
          let y = mouseY;
          if (x + contentSize[0] > viewSize[0]) {
            x = mouseX - contentSize[0] - 15;
          }
          y = mouseY - contentSize[1] - 10;
          return [x, y];
        },
        axisPointer: {
          type: "line",
          lineStyle: {
            color:
              colorScheme === "dark"
                ? colors["grey"][600]
                : colors["grey"][300],
            width: 1,
            type: "dashed",
          },
        },
      },
      xAxis: {
        name: t["Days"],
        nameLocation: "middle",
        type: "time",
        minInterval: 3600 * 1000 * 24,
        min: startDate,
        max: endDate,
        axisLine: {
          onZero: false,
          show: false,
        },
        axisLabel: {
          fontFamily: "Inter",
          fontWeight: 300,
          fontSize: 12,
          lineHeight: 16,
          color:
            colorScheme === "dark" ? colors["grey"][400] : colors["grey"][600],
          margin: 12,
          formatter: (value) =>
            timeFormatter(value, startDate, endDate, previousDateRef),
          rich: {
            a: {
              fontWeight: "bold",
            },
          },
          showMinLabel: true,
          showMaxLabel: true,
          hideOverlap: true,
          padding: [0, 20],
        },
        splitLine: { show: false },
        axisTick: {
          lineStyle: {
            color:
              colorScheme === "dark"
                ? colors["grey"][600]
                : colors["grey"][300],
          },
        },
        nameTextStyle: {
          color:
            colorScheme === "dark" ? colors["grey"][400] : colors["grey"][600],
          fontSize: 12,
          fontWeight: 300,
          lineHeight: 16,
          padding: [20, 0, 0, 0],
          fontFamily: "Inter",
        },
      },
      yAxis: {
        name: t["Depth (m)"],
        nameLocation: "start",
        nameTextStyle: {
          padding: [0, 20, 5, 0],
          fontFamily: "Inter",
          fontWeight: 300,
          fontSize: 12,
          color:
            colorScheme === "dark" ? colors["grey"][400] : colors["grey"][600],
        },
        inverse: true,
        min: 0,
        max: max,
        interval: interval,
        splitNumber: 3,
        type: "value",
        startValue: 0,
        axisLine: { onZero: false, show: false },
        splitLine: {
          lineStyle: {
            type: "solid",
            color:
              colorScheme === "dark"
                ? colors["grey"][600]
                : colors["grey"][300],
          },
        },
        axisLabel: {
          color:
            colorScheme === "dark" ? colors["grey"][400] : colors["grey"][600],
        },
        axisTick: {
          lineStyle: {
            color:
              colorScheme === "dark"
                ? colors["grey"][600]
                : colors["grey"][300],
          },
        },
      },
      grid: {
        left: 100,
        right: 30,
        height: "80%",
        containLabel: true,
      },
      series: series,
    };
  }, [
    t,
    colorScheme,
    tooltipContainer,
    startDate,
    endDate,
    series,
    interval,
    max,
  ]);

  const legendOptions: EChartsOption = useMemo(() => {
    return {
      legend: {
        show: true,
        top: 5,
        left: 96,
        width: "94%",
        itemHeight: 0,
        itemWidth: 20,
        formatter: (name) => name,
        textStyle: {
          height: 20,
          fontWeight: 300,
          fontSize: 10,
          lineHeight: 15,
          fontFamily: "Inter",
          color:
            colorScheme === "dark" ? colors["grey"][100] : colors["grey"][800],
        },
        inactiveColor: colors["grey"][600],
      },
      xAxis: { show: false },
      yAxis: { show: false },
      series: wellbores.map((wellbore, index) => ({
        name: wellbore.name,
        id: wellbore.id,
        type: "line" as const,
        lineStyle: {
          color:
            colorsArray(colorScheme)[index % colorsArray(colorScheme).length],
        },
        showSymbol: false,
      })),
    };
  }, [wellbores, colorScheme]);

  echarts.use([
    DatasetComponent,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    TransformComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
  ]);

  const onEvents = {
    click: (
      e: EchartsEventParams<(typeof wellbores)[number]["data"][number]>
    ) => {
      if (!e.seriesId) return;
      if (!onClick) return;
      onClick(new Event("click"), [e.seriesId, ...e.data]);
    },
    legendselectchanged: handleLegendSelect,
  };

  const renderLegend = () => {
    if (isError || isEmpty) return null;
    return (
      <Box bgcolor="background.surface">
        <Checkbox
          sx={{ paddingLeft: 20 }}
          color="neutral"
          variant="outlined"
          size="sm"
          label={t["Hide All"]}
          onChange={handleDisableAll}
        />

        <EChartsRenderer
          ref={legendChartRef}
          echarts={echarts}
          option={legendOptions}
          style={{
            width: "95%",
            height: "90%",
          }}
          onEvents={onEvents}
        />
      </Box>
    );
  };

  if (isLoading) {
    return (
      <Grid
        height={520}
        container
        alignItems="center"
        width="100%"
        gap={3}
        paddingLeft={14}
      >
        <Box width="100%">
          <Skeleton
            loading={isLoading}
            variant="rectangular"
            height={440}
          ></Skeleton>
        </Box>

        <Checkbox
          color="neutral"
          variant="outlined"
          size="md"
          label={t["Hide All"]}
          onChange={handleDisableAll}
        />
        <Box width="100%" paddingBottom={2}>
          <Skeleton
            loading={isLoading}
            variant="rectangular"
            height={26}
          ></Skeleton>
        </Box>
      </Grid>
    );
  }

  return (
    <>
      <SectionErrorState
        isError={isError}
        isEmpty={isEmpty}
        onOpenFeedbackClick={onOpenFeedbackClick}
        onAskForHelpClick={onAskForHelpClick}
        showGradient={false}
      >
        <EChartsRenderer
          ref={mainChartRef}
          notMerge={true}
          lazyUpdate={true}
          echarts={echarts}
          option={isError || isEmpty ? {} : options}
          onEvents={onEvents}
          style={{ height: 440, width: "100%" }}
          showLoading={isLoading}
          opts={{ renderer }}
        />
      </SectionErrorState>
      {renderLegend()}
    </>
  );
}

export default forwardRef(DepthLineChartAsset);
