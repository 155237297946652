import * as echarts from "echarts/core";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ComponentProps,
} from "react";
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LegendComponent,
} from "echarts/components";
import EChartsRenderer from "../../helpers/ECharts/EChartsWrapper";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { Box, Grid, Skeleton, useColorScheme } from "@mui/joy";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import useTooltipContainer from "../../helpers/useTooltipContainer";
import SectionErrorState from "../SectionErrorState/SectionErrorState";
import { getChartOptions } from "./helpers";
import { translationStrings } from "./constants";

echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  LegendComponent,
]);

type Status = "PRT" | "NPT" | "WOW";

type ActualDataPoint = [
  date: number,
  depth: number,
  timestamp: Date | string,
  description: string,
  status: Status,
];
type PlannedDataPoint = [date: number, depth: number];

interface WellboreTimeDepthGraphProps {
  actual: ActualDataPoint[];
  planned: PlannedDataPoint[];
  isLoading?: boolean;
  isError?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  renderer?: NonNullable<
    ComponentProps<typeof EChartsRenderer>["opts"]
  >["renderer"];
  onOpenFeedbackClick: () => void;
  onAskForHelpClick: () => void;
}

type SelectionState = Record<Status, boolean>;

export default function WellboreTimeDepthGraph({
  isLoading,
  actual,
  planned,
  translations,
  isError = false,
  renderer,
  onOpenFeedbackClick,
  onAskForHelpClick,
}: WellboreTimeDepthGraphProps) {
  const isEmpty = !actual.length;
  const t = useMemo(
    () => ({ ...defaultTranslations(translationStrings), ...translations }),
    [translations]
  );
  const { colorScheme } = useColorScheme();
  const tooltipContainer = useTooltipContainer();
  const [heightPercent, setHeightPercent] = useState(0);
  const [selection, setSelection] = useState<SelectionState>({
    PRT: true,
    NPT: true,
    WOW: true,
  });

  // Handle animation for height transition
  useEffect(() => {
    if (isEmpty) return;

    const animateHeight = () => {
      setHeightPercent(0);
      const duration = 1000;
      const delay = 200;
      let startTime: number | null = null;
      let animationId: number | undefined;

      const timeoutId = setTimeout(() => {
        const animate = (timestamp: number): void => {
          if (!startTime) startTime = timestamp;

          const elapsed = timestamp - startTime;
          const rawProgress = Math.min(elapsed / duration, 1);
          const progress = 1 - Math.pow(1 - rawProgress, 3);

          setHeightPercent(progress);

          if (rawProgress < 1) {
            animationId = requestAnimationFrame(animate);
          }
        };

        animationId = requestAnimationFrame(animate);
      }, delay);

      return () => {
        clearTimeout(timeoutId);
        if (animationId) cancelAnimationFrame(animationId);
      };
    };

    return animateHeight();
  }, [isEmpty]);

  const options = useMemo(() => {
    return getChartOptions({
      actual,
      planned,
      selection,
      colorScheme: colorScheme ?? "light",
      t,
      tooltipContainer,
      heightPercent,
    });
  }, [
    actual,
    planned,
    selection,
    colorScheme,
    t,
    tooltipContainer,
    heightPercent,
  ]);

  const handleLegendSelectChanged = useCallback(
    (params: { name: string; selected: Record<string, boolean> }) => {
      const statusLabels: Record<Status, string> = {
        PRT: t["Productive time"],
        NPT: t["Non productive time"],
        WOW: t["Waiting on weather"],
      };

      let selectedStatus: Status | null = null;
      if (params.name === statusLabels.PRT) {
        selectedStatus = "PRT";
      } else if (params.name === statusLabels.NPT) {
        selectedStatus = "NPT";
      } else if (params.name === statusLabels.WOW) {
        selectedStatus = "WOW";
      }

      if (selectedStatus) {
        const isSelected = params.selected[params.name];
        setSelection((prev) => ({ ...prev, [selectedStatus]: isSelected }));
      }
    },
    [t]
  );

  const onEvents = { legendselectchanged: handleLegendSelectChanged };

  if (isLoading) {
    return (
      <Grid height={520} container alignItems="center" width="100%" gap={3}>
        <Box width="100%">
          <Skeleton loading={true} variant="rectangular" height={520} />
        </Box>
      </Grid>
    );
  }

  return (
    <SectionErrorState
      isError={isError}
      isEmpty={isEmpty}
      onOpenFeedbackClick={onOpenFeedbackClick}
      onAskForHelpClick={onAskForHelpClick}
      showGradient={false}
    >
      <EChartsRenderer
        notMerge={true}
        lazyUpdate={true}
        echarts={echarts}
        option={isError || isEmpty ? {} : options}
        style={{ height: 520, width: "100%" }}
        opts={{ renderer }}
        onEvents={onEvents}
      />
    </SectionErrorState>
  );
}
