import * as Sentry from "@sentry/react";
import { ThemeWrapper } from "library-ui-components";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserAuthWrapper } from "library-frontend-utils/auth";
import config from "../deploy";
import { ErrorPage } from "library-ui-components/pages";
import MetricsWrapper from "library-frontend-utils/metrics";

const queryClient = new QueryClient();

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
      <BrowserAuthWrapper config={config}>
        <MetricsWrapper>
          <QueryClientProvider client={queryClient}>
            <ThemeWrapper>
              <RouterProvider router={router} />
              <ReactQueryDevtools initialIsOpen={false} />
            </ThemeWrapper>
          </QueryClientProvider>
        </MetricsWrapper>
      </BrowserAuthWrapper>
    </Sentry.ErrorBoundary>
  );
}

export default App;
