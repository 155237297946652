import { Box, IconButton } from "@mui/joy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChevronDownCircleIcon,
  NestedRowDividerIcon,
  NestedRowDividerLastIcon,
} from "../..";
import type { Row } from "@tanstack/react-table";

interface ExpandableCellProps<TData> {
  row: Row<TData>;
  onClickExpand?: () => void;
}

export default function ExpandableCell<RowData>({
  row,
  onClickExpand,
}: ExpandableCellProps<RowData>) {
  const parentSubRows = row.getParentRow()?.subRows;
  const isLast =
    parentSubRows?.length && parentSubRows.length - 1 === row.index;

  function Content() {
    if (row.getCanExpand()) {
      return (
        <IconButton
          aria-label={`expand-${row.index.toString()}`}
          variant="plain"
          onClick={onClickExpand}
        >
          <FontAwesomeIcon
            style={{
              width: 20,
              padding: 2,
              cursor: "pointer",
              margin: "0 auto",
              transition: "transform 0.3s ease-in-out",
              transform: row.getIsExpanded() ? "rotate(0)" : "rotate(-90deg)",
            }}
            icon={ChevronDownCircleIcon}
          />
        </IconButton>
      );
    }

    if (row.parentId) {
      if (isLast) {
        return (
          <FontAwesomeIcon
            icon={NestedRowDividerLastIcon}
            style={{
              width: 35,
              height: 60,
              stroke: "var(--joy-palette-neutral-outlinedBorder)",
              fill: "transparent",
            }}
          />
        );
      }

      return (
        <FontAwesomeIcon
          icon={NestedRowDividerIcon}
          style={{
            width: 35,
            height: 60,
            stroke: "var(--joy-palette-neutral-outlinedBorder)",
          }}
        />
      );
    }

    return <Box width={24}></Box>;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box
        display="flex"
        alignItems="center"
        sx={{
          "svg[data-icon='nested-row-divider-last'] path ": {
            fill: "transparent",
            strokeWidth: "2px",
          },
        }}
      >
        <Content />
      </Box>
    </Box>
  );
}
