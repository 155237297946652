import type { EChartsOption } from "echarts";
import colors from "../../tokens/colors";
import { formatNumber } from "library-frontend-utils/helpers";
import TooltipFormatter from "./TooltipFormatter";
import {
  calculateMaxXValue,
  generateMarkAreasData,
  getMarkAreaLabelColor,
} from "./utils";
import type useTooltipContainer from "../../helpers/useTooltipContainer";

export function createChartOptions({
  dsvCost,
  afeCost,
  dsvPredictiveCost,
  afeActivities,
  currency,
  colorScheme,
  t,
  tooltipContainer,
  chartWidth,
  isDark,
}: {
  dsvCost: [duration: number, cost: number][];
  afeCost: [duration: number, cost: number][];
  dsvPredictiveCost: [duration: number, cost: number][];
  afeActivities: { name?: string; xAxis: number }[][];
  currency?: string;
  colorScheme: string;
  t: Record<string, string>;
  tooltipContainer: ReturnType<typeof useTooltipContainer>;
  chartWidth: number;
  isDark: boolean;
}): EChartsOption {
  const baseTextStyle = {
    fontFamily: "Inter",
    fontWeight: 300,
    fontSize: 12,
    lineHeight: 16,
    color: isDark ? colors["grey"][400] : colors["grey"][600],
  };
  const maxXValue = calculateMaxXValue(dsvCost, afeCost);

  const markAreasData = generateMarkAreasData(
    afeActivities,
    maxXValue,
    dsvCost,
    isDark,
    chartWidth
  );

  const getMarkAreaLabel = getMarkAreaLabelColor(dsvCost, isDark);

  const seriesMetadata = {
    dsvCost: {
      title: t["Est. VoWD"],
      color: isDark ? colors["grey"][100] : colors["grey"][900],
    },
    afeCost: {
      title: t["Planned AFE"],
      color: isDark ? colors["grey"][600] : colors["grey"][400],
    },
    dsvCostPrediction: {
      title: t["DSV Cost Prediction"],
      color: isDark ? colors["grey"][100] : colors["grey"][900],
    },
    activityDuration: {
      title: t["Actual activity duration"],
      color: colors.blue[400],
    },
  };

  return {
    title: {
      textStyle: {
        fontWeight: 300,
        fontSize: 20,
        fontFamily: "Inter",
        lineHeight: 24,
        color:
          colorScheme === "dark" ? colors["grey"][100] : colors["grey"][800],
      },
    },
    legend: [
      {
        show: true,
        selectedMode: false,
        itemStyle: { color: "transparent" },
        data:
          dsvCost.length > 0
            ? [
                seriesMetadata.dsvCost.title,
                seriesMetadata.dsvCostPrediction.title,
                seriesMetadata.afeCost.title,
              ]
            : [seriesMetadata.afeCost.title],
        right: 185,
        top: 0,
        itemWidth: 20,
        itemHeight: 25,
        itemGap: 15,
        textStyle: {
          fontFamily: "Inter",
          fontWeight: 300,
          fontSize: 12,
          lineHeight: 16,
          color: isDark ? colors["grey"][100] : colors["grey"][800],
        },
      },
      {
        show: true,
        selectedMode: true,
        itemStyle: {
          color: colors["blue"][100],
          borderWidth: 1,
          borderColor: colors["blue"][400],
        },
        data: [seriesMetadata.activityDuration.title],
        itemWidth: 16,
        itemHeight: 16,
        itemGap: 15,
        right: 20,
        textStyle: {
          fontFamily: "Inter",
          fontWeight: 300,
          fontSize: 12,
          lineHeight: 16,
          color: isDark ? colors["grey"][100] : colors["grey"][800],
        },
      },
    ],
    tooltip: {
      trigger: "axis",
      show: true,
      borderWidth: 0,
      backgroundColor: "transparent",
      extraCssText: "box-shadow: none;",
      formatter: (params) => {
        const paramsArray = Array.isArray(params) ? params : [params];
        if (dsvCost.length === 0) {
          return tooltipContainer(TooltipFormatter, params, {
            currency,
            afeCost,
          });
        }

        const hasDsvSeries = paramsArray.some(
          (param) =>
            param.seriesIndex === 0 && param.seriesName === t["Est. VoWD"]
        );

        if (!hasDsvSeries) {
          return "";
        }

        return tooltipContainer(TooltipFormatter, params, {
          currency,
          dsvCost,
          afeCost,
        });
      },
      axisPointer: {
        type: "line",
        snap: false,
        lineStyle: {
          color: isDark ? colors["grey"][400] : colors["grey"][600],
          width: 1,
          type: "solid",
        },
      },
    },
    xAxis: {
      name: t["Days"],
      splitLine: { show: false },
      nameLocation: "middle",
      nameGap: 30,
      min: 0,
      type: "value",
      axisLine: { onZero: false, show: false },
      axisLabel: {
        ...baseTextStyle,
        showMinLabel: true,
        showMaxLabel: true,
        hideOverlap: true,
        padding: [0, 20],
        formatter: (value: number) =>
          Number.isInteger(value)
            ? String(value)
            : formatNumber(Number(value), 1),
      },
      axisTick: {
        lineStyle: {
          color: isDark ? colors["grey"][600] : colors["grey"][300],
        },
      },
      nameTextStyle: { ...baseTextStyle },
    },
    yAxis: {
      name: currency ? `Cost (${currency})` : t["Cost (NOK)"],
      nameLocation: "end",
      nameTextStyle: { ...baseTextStyle, padding: [0, 20, 5, 0] },
      startValue: 0,
      min: 0,
      type: "value",
      axisLine: { onZero: false, show: false },
      splitLine: {
        lineStyle: {
          type: "solid",
          color: isDark ? colors["grey"][600] : colors["grey"][300],
        },
      },
      axisLabel: { color: isDark ? colors["grey"][400] : colors["grey"][600] },
      axisTick: {
        lineStyle: {
          color: isDark ? colors["grey"][600] : colors["grey"][300],
        },
      },
    },
    grid: { left: 20, right: 20, containLabel: true },
    series: [
      // DSV Cost line
      {
        data: dsvCost,
        animation: false,
        name: seriesMetadata.dsvCost.title,
        type: "line" as const,
        itemStyle: { color: seriesMetadata.dsvCost.color },
        lineStyle: {
          width: 2,
          type: "solid",
          color: seriesMetadata.dsvCost.color,
        },
        z: 10,
        showSymbol: false,
        symbol: "circle",
        symbolSize: 4,
        emphasis: {
          scale: 2,
          lineStyle: { width: 2 },
          itemStyle: {
            color: colors["grey"][100],
            borderColor: colors["grey"][900],
            borderWidth: 2,
          },
        },
      },
      // AFE Cost line
      {
        data: afeCost,
        name: seriesMetadata.afeCost.title,
        type: "line" as const,
        itemStyle: { color: seriesMetadata.afeCost.color },
        lineStyle: { width: 2, color: seriesMetadata.afeCost.color },
        showSymbol: false,
        symbol: "circle",
        symbolSize: dsvCost.length > 0 ? 0 : 4,
        silent: dsvCost.length > 0 ? true : false,
        animation: false,
        emphasis: {
          scale: dsvCost.length > 0 ? 0 : 2,
          disabled: dsvCost.length > 0 ? true : false,
          itemStyle: {
            color: colors["grey"][100],
            borderColor: colors["grey"][900],
            borderWidth: 2,
          },
          lineStyle: { width: 2, color: seriesMetadata.afeCost.color },
        },
      },
      // DSV Cost Prediction line
      {
        data: dsvPredictiveCost,
        name: seriesMetadata.dsvCostPrediction.title,
        type: "line" as const,
        legendHoverLink: false,
        animation: false,
        itemStyle: {
          color: seriesMetadata.dsvCostPrediction.color,
          borderType: "dashed",
        },
        lineStyle: {
          width: 2,
          type: "dashed",
          color: seriesMetadata.dsvCostPrediction.color,
        },
        showSymbol: false,
        silent: true,
        symbolSize: 0,
        emphasis: {
          scale: 0,
          disabled: true,
          itemStyle: {
            color: seriesMetadata.dsvCostPrediction.color,
            borderType: "dashed",
          },
          lineStyle: {
            width: 2,
            type: "dashed",
            color: seriesMetadata.dsvCostPrediction.color,
          },
        },
      },
      // Activities markArea series (separate from AFE Cost line)
      {
        name: t["Actual activity duration"],
        type: "custom",
        renderItem: () => {
          return { type: "rect", children: [] };
        },
        data: [0],
        silent: true,
        markArea: {
          silent: true,
          label: {
            color: getMarkAreaLabel,
            fontSize: 10,
            lineHeight: 1.5,
            fontWeight: 300,
            rotate: -90,
            position: ["50%", 10],
            fontFamily: "Inter",
            show: true,
            formatter: function (params) {
              const text = params.name || "Activity";
              const MAX_CHARS = 50;
              if (text.length > MAX_CHARS) {
                return text.substring(0, MAX_CHARS - 3) + "...";
              }

              return text;
            },
          },
          data: markAreasData,
        },
      },
    ],
  };
}
