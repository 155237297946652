import * as echarts from "echarts/core";
import {
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ComponentProps,
  type MutableRefObject,
} from "react";
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LegendComponent,
  VisualMapComponent,
} from "echarts/components";
import EChartsRenderer from "../../helpers/ECharts/EChartsWrapper";
import { LineChart, CustomChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { Box, Grid, Skeleton, useColorScheme } from "@mui/joy";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import useTooltipContainer from "../../helpers/useTooltipContainer";
import SectionErrorState from "../SectionErrorState/SectionErrorState";
import { translationStrings } from "./utils";
import { isRef } from "../../helpers/utility";
import type ReactEChartsCore from "echarts-for-react/lib/core";
import { createChartOptions } from "./helpers";

type WellboreCostGraphProps = {
  dsvCost: [duration: number, cost: number][];
  afeCost: [duration: number, cost: number][];
  dsvPredictiveCost: [duration: number, cost: number][];
  afeActivities: { name?: string; xAxis: number }[][];
  currency?: string;
  isLoading?: boolean;
  isError?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  renderer?: NonNullable<
    ComponentProps<typeof EChartsRenderer>["opts"]
  >["renderer"];
  onOpenFeedbackClick: () => void;
  onAskForHelpClick: () => void;
};

echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LineChart,
  CustomChart,
  CanvasRenderer,
  UniversalTransition,
  LegendComponent,
  VisualMapComponent,
]);

function WellboreCostGraph(
  {
    dsvCost,
    afeCost,
    dsvPredictiveCost,
    afeActivities,
    currency,
    translations,
    isLoading,
    isError = false,
    renderer,
    onOpenFeedbackClick,
    onAskForHelpClick,
  }: WellboreCostGraphProps,
  ref: ComponentProps<typeof EChartsRenderer>["ref"]
) {
  const { colorScheme } = useColorScheme();
  const tooltipContainer = useTooltipContainer();
  const t = useMemo(
    () => ({ ...defaultTranslations(translationStrings), ...translations }),
    [translations]
  );
  const chartRef = useRef<ReactEChartsCore | null>(null);
  const [chartWidth, setChartWidth] = useState<number>(0);
  const isDark = colorScheme === "dark";
  const isEmpty =
    !dsvCost.length && !afeCost.length && !dsvPredictiveCost.length;

  useEffect(() => {
    if (isRef(ref)) {
      (ref as MutableRefObject<unknown>).current = chartRef.current;
    }
  }, [ref]);

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartRef.current) {
        const chartInstance = chartRef.current.getEchartsInstance();
        const width = chartInstance.getWidth();
        setChartWidth(width);
        chartInstance.resize();
      }
    };

    const initTimer = setTimeout(() => {
      updateChartWidth();
    }, 200);

    window.addEventListener("resize", updateChartWidth);

    return () => {
      clearTimeout(initTimer);
      window.removeEventListener("resize", updateChartWidth);
    };
  }, []);

  const options = useMemo(() => {
    return createChartOptions({
      dsvCost,
      afeCost,
      dsvPredictiveCost,
      afeActivities,
      currency,
      colorScheme: colorScheme ?? "light",
      t,
      tooltipContainer,
      chartWidth,
      isDark,
    });
  }, [
    colorScheme,
    currency,
    afeCost,
    dsvCost,
    dsvPredictiveCost,
    afeActivities,
    t,
    tooltipContainer,
    chartWidth,
    isDark,
  ]);

  const onEvents = useMemo(() => {
    return {
      finished: () => {
        if (chartRef.current) {
          const chartInstance = chartRef.current.getEchartsInstance();
          const width = chartInstance.getWidth();
          setChartWidth(width);
        }
      },
    };
  }, []);

  if (isLoading) {
    return (
      <Grid height={520} container alignItems="center" width="100%" gap={3}>
        <Box width="100%">
          <Skeleton loading={true} variant="rectangular" height={520} />
        </Box>
      </Grid>
    );
  }

  return (
    <SectionErrorState
      isError={isError}
      isEmpty={isEmpty}
      onOpenFeedbackClick={onOpenFeedbackClick}
      onAskForHelpClick={onAskForHelpClick}
      showGradient={false}
    >
      <EChartsRenderer
        ref={chartRef}
        notMerge={true}
        lazyUpdate={true}
        echarts={echarts}
        option={isError || isEmpty ? {} : options}
        style={{ height: 500, width: "100%" }}
        opts={{ renderer }}
        onEvents={onEvents}
      />
    </SectionErrorState>
  );
}

export default forwardRef(WellboreCostGraph);
