import {
  flexRender,
  type CellContext,
  type Column,
  type ColumnDefTemplate,
  type HeaderContext,
  type Table,
} from "@tanstack/react-table";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";
import { isValidElement } from "react";
import { startOfISOWeek } from "date-fns";
import { withDefault } from "use-query-params";

// TODO: remove this cast since elysia do this cast in the client
function forceDateCast(date: Date | string) {
  const dateCast = typeof date === "string" ? new Date(date) : date;
  if (isNaN(dateCast.getTime())) throw new Error("Invalid date");
  return dateCast;
}

export function formatDate(dateToFormat: Date | string) {
  dateToFormat = forceDateCast(dateToFormat);
  return new Intl.DateTimeFormat(navigator.language).format(dateToFormat);
}

export function formatDateWithHours(dateToFormat: Date | string) {
  dateToFormat = forceDateCast(dateToFormat);
  const formattedDate = new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(dateToFormat);

  return formattedDate;
}

export function formatPercentage(
  percentage: number,
  fractionDigits: number | undefined = 1,
  trailingZeroDisplay?: Intl.NumberFormatOptions["trailingZeroDisplay"]
) {
  return new Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: fractionDigits,
    style: "percent",
    trailingZeroDisplay,
  }).format(percentage);
}

export function truncateText(text: string, maxCharacters: number) {
  return text.length > maxCharacters
    ? text.slice(0, maxCharacters) + "..."
    : text;
}

export function formatNumber(
  number: number,
  fractionDigits: number,
  signDisplay?: keyof Intl.NumberFormatOptionsSignDisplayRegistry,
  trailingZeroDisplay?: Intl.NumberFormatOptions["trailingZeroDisplay"]
) {
  if (number === 0) {
    return "0";
  }

  const formattedNumber = new Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    signDisplay,
    trailingZeroDisplay,
  }).format(number);

  return formattedNumber;
}

export function formatCurrency(
  num: number,
  currency: string,
  currencyDisplay: keyof Intl.NumberFormatOptionsCurrencyDisplayRegistry = "narrowSymbol"
) {
  return new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency,
    currencyDisplay,
  }).format(num);
}

export function cleanQueryParams<T extends object>(
  object: T
): { [P in keyof T]: NonNullable<T[P]> } {
  const newObj = {} as unknown as { [P in keyof T]: NonNullable<T[P]> };
  for (const key in object) {
    const value = object[key];
    if (value !== null && value !== undefined && value !== "")
      newObj[key] = value;
  }
  return newObj;
}

export function getLocaleDateFormat() {
  return new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
    .formatToParts(new Date())
    .reduce((acc, part) => {
      const { type, value } = part;
      if (type === "year") {
        return acc + "yyyy";
      } else if (type === "month") {
        return acc + "MM";
      } else if (type === "day") {
        return acc + "dd";
      } else {
        return acc + value;
      }
    }, "");
}

/**
 * Formats table cells for clipboard copying.
 *
 * This function takes an array of cell objects and formats them into strings
 * suitable for copying to the clipboard. It supports formatting for headers,
 * footers, and regular cells.
 */
async function formatTableCellsForClipboard<T extends object>(
  cells: {
    column: Column<T>;
    getContext: () => HeaderContext<T, unknown> | CellContext<T, unknown>;
  }[],
  type: "header" | "footer" | "cell" = "cell"
): Promise<string[]> {
  const promises = cells.map(async ({ column, getContext }) => {
    const cell = flexRender(
      column.columnDef[type as keyof ColumnDefTemplate<T>],
      getContext()
    );
    if (typeof cell === "string") {
      return cell;
    }
    if (isValidElement(cell)) {
      const element = document.createElement("div");
      const root = createRoot(element);
      // Here we use a promise to wait for the next render cycle to complete
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          flushSync(() => {
            root.render(cell);
            resolve();
          });
        });
      });
      const text = element.textContent ?? "";
      root.unmount();
      return text;
    }
    return "";
  });
  return Promise.all(promises);
}

/**
 * Formats a TanStack table for clipboard copying.
 */
export async function formatTanStackTableForClipboard<T extends object>(
  table: Table<T>,
  options?: {
    includeHeaders?: boolean;
    includeFooters?: boolean;
    separator?: string;
  }
): Promise<string> {
  const {
    includeHeaders = true,
    includeFooters = true,
    separator = "\t",
  } = options ?? {};

  const [headerData, rowsData, footerData] = await Promise.all([
    includeHeaders
      ? Promise.all(
          table.getHeaderGroups().map(async (header) => {
            const cellsData = await formatTableCellsForClipboard(
              header.headers.map(({ column, getContext }) => ({
                column,
                getContext,
              })),
              "header"
            );
            return cellsData.join(separator);
          })
        )
      : [],
    Promise.all(
      table.getExpandedRowModel().rows.map(async (row) => {
        const cellsData = await formatTableCellsForClipboard(
          row
            .getVisibleCells()
            .map(({ column, getContext }) => ({ column, getContext }))
        );

        if (row.depth > 0) {
          cellsData[1] = `|-> ${cellsData[1]}`;
        }

        return cellsData.join(separator);
      })
    ),
    includeFooters
      ? Promise.all(
          table.getFooterGroups().map(async ({ headers: footers }) => {
            const cellsData = await formatTableCellsForClipboard(
              footers.map(({ column, getContext }) => ({
                column,
                getContext,
              })),
              "footer"
            );
            return cellsData.join(separator);
          })
        )
      : [],
  ]);

  const output: string[] = [];

  if (headerData.length > 0) {
    output.push(...headerData);
  }

  output.push(...rowsData);

  if (footerData.length > 0) {
    output.push(...footerData);
  }

  return output.join("\n");
}

export function getUTCMidnight(date: Date) {
  // Use UTC methods to create a new date at UTC midnight
  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate() + 1 // Add one day to get to the next midnight UTC
    )
  );
}

export function getStartOfWeekUTCMidnight(date: Date) {
  const local = startOfISOWeek(date);
  return new Date(
    Date.UTC(
      local.getUTCFullYear(),
      local.getUTCMonth(),
      local.getUTCDate() + 1
    )
  );
}

export function StringEnumParam<const T extends string[]>(
  allowedValues: T,
  defaultValue: T[number]
) {
  return withDefault<
    T[number] | null | undefined,
    T[number],
    T[number] | null | undefined
  >(
    {
      encode(str) {
        if (str === null) return null;
        if (str === undefined) return undefined;
        if (allowedValues.includes(str)) return str;
        throw new Error(`Invalid parameter value: ${str}`);
      },
      decode(input) {
        if (Array.isArray(input))
          throw new Error("Invalid parameter value. It cannot be an array.");
        if (input === null) return null;
        if (input === undefined) return undefined;
        if (allowedValues.includes(input)) return input as T[number];
        throw new Error(`Invalid parameter value: ${input}`);
      },
    },
    defaultValue
  );
}

export const removeStringSpace = (text: string): string => {
  return text.replace(/\s+/g, "");
};

export function appendQueryParams(
  baseUrl: string,
  params: Record<string, string | number | boolean | undefined | null>
): string {
  const cleanBaseUrl = baseUrl.replace(/\/+$/, "");
  if (Object.keys(params).length === 0) {
    return cleanBaseUrl;
  }
  const hasParams = cleanBaseUrl.includes("?");
  const queryString = Object.entries(params)
    .map(([key, value]) =>
      value !== null && value !== undefined
        ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        : ""
    )
    .join("&");
  return hasParams
    ? `${cleanBaseUrl}&${queryString}`
    : `${cleanBaseUrl}?${queryString}`;
}

export function isString<T>(
  value: T | null | undefined
): value is NonNullable<T> {
  return typeof value === "string" && Boolean(value);
}
