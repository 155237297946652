export const fontSize = {
  xl4: "2.5rem",
  xl3: "2rem",
  lg: "1rem",
  md: "0.875rem",
  sm: "0.75rem",
  xs: "0.625rem",
};

export const fontWeight = {
  xs: 200,
  sm: 300,
  md: 400,
  lg: 600,
};

export const lineHeight = {
  xs: 1.2,
  sm: 1.2,
  md: 1.5,
  lg: 1.5,
  xl: 1.5,
};

const fontFamily = ['"Inter", "sans-serif"'].join(",");

const typography = {
  fontSize,
  fontWeight,
  lineHeight,
  fontFamily,
};

export default typography;
