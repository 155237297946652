import type { TopLevelFormatterParams } from "echarts/types/dist/shared";
import colors from "../../tokens/colors";
import { format } from "date-fns";
import { Card, Grid, Typography } from "@mui/joy";
import { formatNumber } from "library-frontend-utils/helpers";
import radius from "../../tokens/radius";

type TooltipFormatterProps = {
  param: TopLevelFormatterParams;
};

export default function TooltipFormatter({ param }: TooltipFormatterProps) {
  if (!Array.isArray(param) || !Array.isArray(param[0].value)) return "";
  const date = format(param[0].value[0] as string, "dd MMM yyyy");
  return (
    <Card
      sx={{
        borderRadius: radius.sm,
        padding: 0,
        boxShadow: "md",
        backgroundColor: "common.white",
        borderColor: "neutral.300",
      }}
    >
      <Typography
        sx={{
          color: colors["grey"][800],
          padding: 2,
          borderBottom: `1px solid var(--joy-palette-neutral-300)`,
        }}
        level="title-md"
        fontWeight={600}
      >
        {date}
      </Typography>

      <Grid
        container
        direction="column"
        sx={{ width: 210, padding: 2, pt: 0 }}
        gap={1}
      >
        {param.map((parameters, index) => {
          if (!Array.isArray(parameters.value)) return "";
          const displayValue =
            Math.round(parseFloat(parameters.value[1] as string) * 100) / 100;

          return (
            <Grid
              container
              key={`${displayValue.toString()}${index.toString()}`}
              justifyContent="space-between"
              alignItems="center"
              direction="row"
            >
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    fontWeight: 300,
                    display: "inline-block",
                    width: 10,
                    height: 10,
                    background: parameters.color as string,
                    borderRadius: "50%",
                    marginRight: 5,
                  }}
                ></span>

                <Typography
                  sx={{ color: colors["grey"][800] }}
                  level="body-sm"
                  fontWeight={300}
                >
                  {parameters.seriesName}
                </Typography>
              </Grid>
              <Grid sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    color: colors["grey"][800],
                    fontVariant: "tabular-nums",
                  }}
                  level="body-sm"
                  fontWeight={600}
                >
                  {formatNumber(Number(displayValue), 1)} m
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
}
