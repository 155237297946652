import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { getCurrentStackName, getCurrentRelease } from "../infra";
import feedbackIntegration from "./helpers/feedbackIntegration";

const environment = getCurrentStackName();

const urlsToIgnore = [
  "/openapi.json",
  "/docs",
  "/redoc",
  "/favicon.ico",
  "/live",
  "/ready",
  "/version",
  "/report",
  "/mreport",
];

Sentry.init({
  dsn: "https://d73f5afa07e9fcb0af1719ae8f1d7b67@o4505051041628160.ingest.us.sentry.io/4507628646367232",
  tunnel: environment === "local" ? "http://localhost:3001/report" : "/report",
  enabled: environment !== "local",
  release: getCurrentRelease(),
  environment,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [/.+/],
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),
    feedbackIntegration,
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/.+/],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSendTransaction: (event) => {
    if (urlsToIgnore.some((url) => event.request?.url?.includes(url)))
      return null;
    return event;
  },
});
