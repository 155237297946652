import {
  LegendComponent,
  TooltipComponent,
  GridComponent,
} from "echarts/components";
import * as echarts from "echarts/core";
import { BarChart, CustomChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  type ComponentProps,
  type MutableRefObject,
} from "react";
import { type EChartsOption } from "echarts";
import EChartsRenderer from "../../helpers/ECharts/EChartsWrapper";
import { makeSeries } from "./helpers/makeSeries";
import { defaultTranslations } from "library-translations";
import { Box, useTheme } from "@mui/joy";
import ActivityChartLoading from "./ActivityChartLoading";
import type ReactEChartsCore from "echarts-for-react/lib/core";
import { isRef } from "../../helpers/utility";
import { OPERATION_TYPES, translationStrings } from "./constants";
import useTooltipContainer from "../../helpers/useTooltipContainer";
import AssetActivityChartTooltip from "./AssetActivityChartTooltip";
import useColors from "./helpers/useColors";
import timeFormatter from "../../helpers/ECharts/timeFormatter";
import generateFacilityWellboreId from "./helpers/generateFacilityWellboreId";
import SectionErrorState from "../SectionErrorState/SectionErrorState";
import type { AssetActivityChartProps } from "./types";
import generateChartHeight from "./helpers/generateChartHeight";
import { chartEventHandlers } from "./helpers/chartEventHandlers";

function AssetActivityChart(
  {
    isLoading = true,
    onClick,
    activityData = [],
    startDate,
    translations,
    isError = false,
    renderer,
    title,
    hideLegend = false,
    endDate,
    onOpenFeedbackClick,
    onAskForHelpClick,
  }: AssetActivityChartProps,
  ref: ComponentProps<typeof EChartsRenderer>["ref"]
) {
  const theme = useTheme();
  const t = useMemo(
    () => ({
      ...defaultTranslations(translationStrings),
      ...translations,
    }),
    [translations]
  );
  const echartsComponentRef = useRef<ReactEChartsCore | null>(null);
  const previousDateRef = useRef<Date | null>(null);
  const disabledSeriesRef = useRef<Record<string, boolean>>({});

  const tooltipContainer = useTooltipContainer();

  useEffect(() => {
    if (isRef(ref)) {
      (ref as MutableRefObject<unknown>).current = echartsComponentRef.current;
    }
  }, [ref]);

  const colors = useColors();

  const operationTypeLegendNames = useMemo(
    () => ({
      Drilling: t["Drilling"],
      Completion: t["Completion"],
      FormationEvaluation: t["Formation Evaluation"],
      Moving: t["Moving"],
      Interruption: t["Interruption"],
      Workover: t["Workover"],
      PlugAbandon: t["Plug Abandon"],
      NotAvailable: t["Not Specified"],
    }),
    [t]
  );

  const operations = useMemo(() => {
    if (!activityData.length) return [];
    const uniqueOperations = activityData.flatMap((facility) =>
      facility.operations.map((operation) => ({
        ...operation,
        activities: operation.activities.map((activity) => ({
          ...activity,
          wellboreId: generateFacilityWellboreId(
            facility.facilityName,
            activity.wellboreId
          ),
        })),
      }))
    );

    return uniqueOperations.sort(
      (a, b) =>
        OPERATION_TYPES.indexOf(a.type) - OPERATION_TYPES.indexOf(b.type)
    );
  }, [activityData]);

  // Add a custom row inside wellbores array to render the title easier
  const wellbores = useMemo(() => {
    if (!activityData.length) return [];
    return activityData.flatMap((facility) => [
      {
        id: `title-${facility.facilityName}`,
        facilityWellboreId: facility.facilityName,
        isTitle: true,
        name: facility.facilityName,
      },
      ...facility.wellbores.map((wellbore) => ({
        ...wellbore,
        id: wellbore.id,
        facilityWellboreId: generateFacilityWellboreId(
          facility.facilityName,
          wellbore.id
        ),
      })),
    ]);
  }, [activityData]);

  const calculateZoom = useCallback(() => {
    const MIN_VISIBLE_ROWS = 7;
    const numberOfRows = wellbores.length;
    if (numberOfRows <= MIN_VISIBLE_ROWS) {
      return {
        start: 0,
        end: 100,
      };
    }

    const rowHeight = 100 / numberOfRows;
    const visiblePercentage = Math.min(100, MIN_VISIBLE_ROWS * rowHeight);

    return {
      start: 0,
      end: visiblePercentage,
    };
  }, [wellbores]);

  const options: EChartsOption = useMemo(() => {
    const shouldEnableDataZoom = wellbores.length > 10 && !isError;
    const uniqueOperations = [...new Set(operations.map((op) => op.type))];
    const legendColors = uniqueOperations.map((op) => colors.operations[op]);
    const displayFullLegend = wellbores.length === 0;

    const zoom = calculateZoom();

    return {
      title: {
        text: title,
        left: "left",
        textStyle: {
          fontFamily: "Inter",
          fontSize: theme.fontSize.xl,
          fontWeight: theme.fontWeight.sm as number,
        },
      },
      legend: {
        selectedMode: true,
        textStyle: {
          fontFamily: "Inter",
          fontSize: theme.fontSize.xs,
          fontWeight: theme.fontWeight.sm as number,
        },
        top: "top",
        bottom: 0,
        right: 12,
        itemGap: 20,
        show: !hideLegend,
        itemHeight: 16,
        itemWidth: 16,
      },
      tooltip: {
        show: false, // is handled by the mouseover event
        trigger: "item",
        position: function (point, _params, _dom, _rect, size) {
          const tooltipWidth = size.contentSize[0];
          const tooltipHeight = size.contentSize[1];
          const x = point[0] - tooltipWidth / 2;
          const y = point[1] - tooltipHeight - 20;
          return [x, y];
        },
        borderWidth: 0,
        backgroundColor: "transparent",
        formatter: (params) =>
          tooltipContainer(AssetActivityChartTooltip, params),
      },
      color: legendColors,
      grid: {
        left: 120,
        right: 30,
        bottom: 10,
        containLabel: true,
      },
      xAxis: {
        type: "time",
        minInterval: 3600 * 1000 * 24,
        min: startDate,
        max: endDate,
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          margin: 10,
          show: wellbores.length === 0 ? false : true,
          formatter: (value) =>
            timeFormatter(value, startDate, endDate, previousDateRef),
          rich: {
            a: {
              fontWeight: "bold",
            },
          },
          showMinLabel: true,
          showMaxLabel: true,
          hideOverlap: true,
          padding: [0, 15],
          color:
            theme.palette.mode === "dark"
              ? theme.palette.neutral[400]
              : theme.palette.neutral[600],
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            width: 1,
            color:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[600]
                : theme.palette.neutral[300],
          },
        },
        z: 12,
      },
      yAxis: {
        inverse: true, // Invert the y-axis to have the first row at the top
        data: wellbores.map((_, index) => index),
        type: "category",
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: { show: false },
        splitLine: { show: false },
      },
      dataZoom: shouldEnableDataZoom
        ? [
            {
              type: "slider",
              yAxisIndex: 0,
              zoomLock: true,
              width: 8,
              right: 10,
              top: 60,
              bottom: 30,
              start: zoom.start,
              end: zoom.end, // Calculate the zoom percentage to show the right numbers of rows
              showDetail: false,
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.neutral[800]
                  : theme.palette.neutral[200],
              borderColor: "transparent",
              fillerColor:
                theme.palette.mode === "dark"
                  ? theme.palette.neutral[600]
                  : theme.palette.neutral[400],
            },
            {
              type: "inside",
              id: "insideY",
              yAxisIndex: 0,
              start: zoom.start,
              end: zoom.end, // Calculate the zoom percentage to show the right numbers of rows
              zoomOnMouseWheel: false,
              moveOnMouseMove: true,
              moveOnMouseWheel: true,
              preventDefaultMouseMove: true,
            },
          ]
        : [],
      series: makeSeries(
        wellbores,
        operations,
        colors,
        operationTypeLegendNames,
        theme,
        displayFullLegend,
        startDate,
        endDate
      ),
    };
  }, [
    operations,
    operationTypeLegendNames,
    wellbores,
    theme,
    title,
    hideLegend,
    colors,
    isError,
    tooltipContainer,
    startDate,
    endDate,
    calculateZoom,
  ]);

  const heightOfTheChart = useMemo(() => {
    if (isError || !wellbores.length) return 400;

    return generateChartHeight(wellbores);
  }, [wellbores, isError]);

  echarts.use([
    TooltipComponent,
    LegendComponent,
    CustomChart,
    BarChart,
    CanvasRenderer,
    GridComponent,
  ]);

  const onEvents = chartEventHandlers({
    onClick,
    echartsComponentRef,
    disabledSeriesRef,
    theme,
    colors,
  });

  if (isLoading) return <ActivityChartLoading />;

  const isEmpty = !isError && wellbores.length === 0;

  return (
    <Box position="relative">
      <SectionErrorState
        isError={isError}
        isEmpty={isEmpty}
        onOpenFeedbackClick={onOpenFeedbackClick}
        onAskForHelpClick={onAskForHelpClick}
      >
        <EChartsRenderer
          ref={echartsComponentRef}
          notMerge={true}
          lazyUpdate={true}
          echarts={echarts}
          option={options}
          onEvents={onEvents}
          style={{
            height: heightOfTheChart,
            width: "100%",
          }}
          opts={{ renderer }}
        />
      </SectionErrorState>
    </Box>
  );
}

export default forwardRef(AssetActivityChart);
