import colors from "../../tokens/colors";

interface MarkAreaStartPoint {
  name?: string;
  xAxis: number;
  itemStyle?: { color: string };
  label?: { show: boolean };
}

interface MarkAreaEndPoint {
  name?: string;
  xAxis: number;
}

type MarkAreaData = Array<[MarkAreaStartPoint, MarkAreaEndPoint]>;

export const translationStrings = [
  "Cost Chart",
  "Loading...",
  "Nothing here yet!",
  "Oops! Something went wrong on our end. Please try again in a few minutes.",
  "No data available to display. Once data is added, it will appear here.",
  "Cost (NOK)", // ISSUE - NOK is static while it should be dynamic
  "Days",
  "Error",
  "Est. VoWD",
  "Planned AFE",
  "DSV Cost Prediction",
  "Actual activity duration",
] as const;

export const calculateMaxXValue = (
  dsvCost: [number, number][],
  afeCost: [number, number][]
): number => {
  let max = 0;

  if (dsvCost.length) {
    const dsvMax = Math.max(...dsvCost.map((point) => point[0]));
    max = Math.max(max, dsvMax);
  }

  if (afeCost.length) {
    const afeMax = Math.max(...afeCost.map((point) => point[0]));
    max = Math.max(max, afeMax);
  }

  return max;
};

export const generateMarkAreasData = (
  afeActivities: { name?: string; xAxis: number }[][],
  maxXValue: number,
  dsvCost: [number, number][],
  isDark: boolean,
  chartWidth?: number
): MarkAreaData => {
  if (!afeActivities.length || !maxXValue) return [];

  const MIN_LABEL_WIDTH_PX = 50;

  const isLabelTooSmall = (duration: number) => {
    if (chartWidth) {
      const durationInPixels = (duration / maxXValue) * chartWidth;
      return durationInPixels < MIN_LABEL_WIDTH_PX;
    } else {
      return true;
    }
  };

  const getColors = (index: number) => {
    if (dsvCost.length > 0) {
      if (isDark) {
        return index % 2 === 0
          ? colors["alphaBlue"][100]
          : colors["alphaBlue"][50];
      } else {
        return index % 2 === 0
          ? colors["alphaBlue"][100]
          : colors["alphaBlue"][50];
      }
    } else {
      if (isDark) {
        return index % 2 === 0 ? colors["grey"][700] : colors["grey"][600];
      } else {
        return index % 2 === 0 ? colors["grey"][200] : colors["grey"][300];
      }
    }
  };

  return afeActivities.map(([activityStart, activityEnd], index) => {
    const duration = activityEnd.xAxis - activityStart.xAxis;

    const start: MarkAreaStartPoint = {
      name: activityStart.name,
      xAxis: activityStart.xAxis,
      itemStyle: {
        color: getColors(index),
        ...(dsvCost.length === 0 && { opacity: 0.3 }),
      },

      label: { show: chartWidth ? !isLabelTooSmall(duration) : false },
    };

    const end: MarkAreaEndPoint = {
      name: activityEnd.name,
      xAxis: activityEnd.xAxis,
    };

    return [start, end];
  });
};

export const getMarkAreaLabelColor = (
  dsvCost: [number, number][],
  isDark: boolean
): string => {
  if (dsvCost.length === 0) {
    return isDark ? colors["grey"][200] : colors["grey"][600];
  }

  return isDark ? colors["blue"][200] : colors["blue"][500];
};
