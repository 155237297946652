import type { Theme } from "@mui/material";
import colors from "../../tokens/colors";
import type { Components } from "@mui/material/styles";
import Radius from "../../tokens/radius";
import Typography from "../../tokens/typography";
import radius from "../../tokens/radius";
import { colorVariant } from "../helpers/colorVariant";
type PaletteColorKey = "primary" | "info" | "error" | "success" | "warning";

const buttonVariants = ["contained", "outlined", "text"] as const;
const buttonColors = [
  "primary",
  "secondary",
  "info",
  "error",
  "success",
  "warning",
] as const;

const colorMap = {
  primary: "blue",
  secondary: "purple",
  info: "grey",
  error: "red",
  success: "green",
  warning: "yellow",
} as const;

const generateButtonStyles = (theme: Theme) => {
  return buttonColors.flatMap((color) =>
    buttonVariants.map((variant) => {
      const isContained = variant === "contained";
      const isInfo = color === "info";
      const isWarning = color === "warning";
      const isDarkMode = theme.palette.mode === "dark";

      let textColor = "";
      if ((isContained && isInfo) || (isContained && isWarning)) {
        textColor = theme.palette.common.black;
      } else if (isContained) {
        textColor = theme.palette.common.white;
      } else if (isInfo) {
        textColor = isDarkMode
          ? theme.palette.common.white
          : theme.palette.common.black;
      } else {
        textColor = isDarkMode
          ? colors[colorMap[color]][200]
          : colors[colorMap[color]][500];
      }

      return {
        props: { color, variant },
        style: {
          color: textColor,
          backgroundColor: isContained && colors[colorMap[color]][500],
          borderColor: isContained ? "none" : colors[colorMap[color]][300],
          ":hover": {
            backgroundColor: isDarkMode
              ? colors[colorMap[color]][600]
              : colors[colorMap[color]][200],
          },
          ":active": {
            backgroundColor: isDarkMode
              ? colors[colorMap[color]][700]
              : colors[colorMap[color]][300],
          },
        },
      };
    })
  );
};

const buttonCustomizations: Components<Theme> = {
  MuiButton: {
    defaultProps: {
      variant: "contained",
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        textTransform: "none",
        variants: [
          ...generateButtonStyles(theme),
          {
            props: { variant: "contained" },
            style: {
              color:
                ownerState.color === "info" || ownerState.color === "warning"
                  ? theme.palette.common.black
                  : theme.palette.common.white,
              backgroundColor:
                ownerState.color === "info"
                  ? theme.palette.common.white
                  : colors[colorMap[ownerState.color as PaletteColorKey]][500],
              ":hover": {
                backgroundColor:
                  ownerState.color === "info"
                    ? colors.grey[200]
                    : colors[
                        colorMap[ownerState.color as PaletteColorKey]
                      ][600],
              },
              ":active": {
                backgroundColor:
                  ownerState.color === "info"
                    ? colors.grey[300]
                    : colors[
                        colorMap[ownerState.color as PaletteColorKey]
                      ][700],
              },
            },
          },
        ],
        "& .MuiButton-startIcon svg, .MuiButton-endIcon svg": {
          color: "inherit",
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.info.main,
          color: colors.grey[500],
          border: "none",
          "& .MuiButton-startIcon svg, .MuiButton-endIcon svg": {
            color: colors.grey[500],
          },
        },
        ".MuiTouchRipple-root": {
          display: "none",
        },
        borderRadius: Radius.xl,
        lineHeight: Typography.lineHeight.sm,
        gap: theme.spacing(ownerState.size === "small" ? 1 : 2),
        paddingBlock: theme.spacing(ownerState.size === "large" ? 2 : 1),
        paddingInline: theme.spacing(ownerState.size === "large" ? 4 : 3),
        ...(ownerState.size === "small" && {
          minHeight: "2rem",
        }),
        ...(ownerState.size === "medium" && {
          minHeight: "2.5rem",
        }),
        ...(ownerState.size === "large" && {
          minHeight: "3rem",
        }),
      }),
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      root: () => ({
        "& .MuiButtonGroup-grouped": {
          borderColor: colors.grey[300],
        },
        "& .MuiButtonGroup-firstButton": {
          borderTopLeftRadius: Radius.sm,
          borderBottomLeftRadius: Radius.sm,
        },
        "& .MuiButtonGroup-lastButton": {
          borderTopRightRadius: Radius.sm,
          borderBottomRightRadius: Radius.sm,
        },
      }),
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
      color: "info",
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const isInfo = ownerState.color === "info";
        const colorKey = ownerState.color || "info";
        const color = colorVariant(theme)[colorKey];

        const iconColor = (() => {
          if (!ownerState.color) return theme.palette.info.contrastText;
          return {
            primary: theme.palette.primary.main,
            info: theme.palette.info.contrastText,
            error: theme.palette.error.main,
            success: theme.palette.success.main,
            warning: theme.palette.warning.main,
            secondary: theme.palette.secondary.main,
            default: theme.palette.info.contrastText,
            inherit: theme.palette.info.contrastText,
          }[ownerState.color];
        })();

        return {
          "&:not(.MuiAutocomplete-endAdornment .MuiIconButton-root):not(.MuiPickersArrowSwitcher-root .MuiIconButton-root)":
            {
              transition: "none",
              "--Icon-color": iconColor,
              borderRadius: radius.xl,
              "&:hover": {
                background: color.hover,
                "--Icon-color": isInfo
                  ? theme.palette.info.contrastText
                  : theme.palette.common.white,
              },
              "&:active": {
                background: color.active,
                "--Icon-color": isInfo
                  ? theme.palette.info.contrastText
                  : theme.palette.common.white,
              },
              ...(ownerState.size === "small" && {
                height: "2rem",
                width: "2rem",
                svg: {
                  fontSize: "1.25rem",
                },
              }),
              ...(ownerState.size === "medium" && {
                height: "2.5rem",
                width: "2.5rem",
                svg: {
                  fontSize: "1.5rem",
                },
              }),
              "&.Mui-disabled": {
                background: theme.palette.action.disabledBackground,
                color: theme.palette.action.disabled,
                svg: {
                  color: theme.palette.action.disabled,
                },
              },
            },
        };
      },
    },
  },
};

export default buttonCustomizations;
